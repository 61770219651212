import React from "react";
import classes from "./styles.module.scss";
import { Link } from "react-router-dom";
import {
  ADD_ARTICLE,
  ENTERTAINMENT,
  HOME,
  LOGIN,
  REGISTER,
} from "../../../Routes/urls";

export default function Options({ active, name }) {
  return (
    <div
      className={`${classes.Navbar__links__options} ${
        active && classes.Navbar__links__options__active
      }`}
    >
      <div className={classes.Navbar__links__options__container}>
        <h4>Links</h4>
        <Link to={REGISTER}>Register</Link>
        <Link to={LOGIN}>Login</Link>
        <Link to={HOME}>Home</Link>
        <Link to={ADD_ARTICLE}>Add Article</Link>
        <Link to={ENTERTAINMENT}>Entertainment</Link>
      </div>
      {name !== "Home" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Dogs</h4>
          <a href="/">Training and Behavior</a>
          <a href="/">Health and Wellness</a>
          <a href="/">Canine Lifestyle</a>
        </div>
      )}
      {name !== "Blogs" && (
        <div className={classes.Navbar__links__options__container}>
          <h4>Birds</h4>
          <a href="/">Feline Behavior Insights</a>
          <a href="/">Healthcare and Grooming</a>
          <a href="/">Cat Living Spaces</a>
        </div>
      )}
      <div className={classes.Navbar__links__options__container}>
        <h4>Cats</h4>
        <a href="/">Avian Behavior and Communication:</a>
        <a href="/">Avian Health and Diet</a>
        <a href="/">Habitat and Cage Setup</a>
        <a href="/">Bird Species Showcases</a>
        <a href="/">Enrichment and Training</a>
      </div>
    </div>
  );
}
