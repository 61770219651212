import React, { useState } from "react";
import classes from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faChevronDown,
  faPaw,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { navlinksArray } from "./constants";
import { Link } from "react-router-dom";
import { ADD_ARTICLE, HOME } from "../../../Routes/urls";
import Options from "./Options";

export default function Navbar({ loggedIn, handleLogin }) {
  const [showOptions, setShowOptions] = useState({
    show: false,
    label: "",
  });

  return (
    <div onMouseLeave={() => setShowOptions({ show: false, label: "" })}>
      <div className={classes.Navbar}>
        <Link
          onMouseEnter={() => setShowOptions({ show: false, label: "" })}
          to={HOME}
          className={classes.Navbar__logo}
        >
          <FontAwesomeIcon
            size="sm"
            icon={faPaw}
            style={{ color: "#fa8d30" }}
          />
          PetKahu
        </Link>
        <div className={classes.Navbar__links}>
          {navlinksArray?.map((link, idx) => (
            <div
              key={"Navbar__links" + link?.name + idx}
              className={`${classes.Navbar__links__item} ${
                idx === 0 && classes.Navbar__links__item__active
              }`}
              onMouseEnter={() =>
                setShowOptions({ show: true, label: link?.name })
              }
            >
              {link?.name}
              <FontAwesomeIcon size="xs" icon={faChevronDown} />
            </div>
          ))}
        </div>
        <div className={classes.Navbar__ctas}>
          {!loggedIn ? (
            <button
              onMouseEnter={() => setShowOptions({ show: false, label: "" })}
              className={classes.Navbar__ctas__login}
              onClick={() => handleLogin(true)}
            >
              Login
              <FontAwesomeIcon icon={faRightToBracket} />
            </button>
          ) : (
            <div
              onMouseEnter={() => setShowOptions({ show: false, label: "" })}
            >
              <Link to={ADD_ARTICLE} className={classes.Navbar__ctas__login}>
                <FontAwesomeIcon icon={faAdd} />
                Add Article
              </Link>
              {/* <button className={classes.Navbar__ctas__login}>
                Logout
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </button> */}
            </div>
          )}
        </div>
      </div>
      <Options active={showOptions?.show} name={showOptions?.label} />
    </div>
  );
}
