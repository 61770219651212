import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as URLS from "./urls";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import FullScreenLoading from "../components/Loader/FullScreenLoading";

const Register = React.lazy(() => import("../pages/Auth/Register"));
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Home = React.lazy(() => import("../pages/Home/Home"));
const AddArticle = React.lazy(() => import("../pages/AddArticle/AddArticle"));
const ArticlePage = React.lazy(() =>
  import("../pages/ArticlePage/ArticlePage")
);
const Entertainment = React.lazy(() =>
  import("../pages/Entertainment/Entertainment")
);

const router = createBrowserRouter([
  {
    path: URLS.REGISTER,
    element: (
      <GuestRoute>
        <Register />
      </GuestRoute>
    ),
  },
  {
    path: URLS.LOGIN,
    element: (
      <GuestRoute>
        <Login />
      </GuestRoute>
    ),
  },
  {
    path: URLS.HOME,
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.ADD_ARTICLE,
    element: (
      <PrivateRoute>
        <AddArticle />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.ARTICLE_PAGE,
    element: (
      <PrivateRoute>
        <ArticlePage />
      </PrivateRoute>
    ),
  },
  {
    path: URLS.ENTERTAINMENT,
    element: (
      <PrivateRoute>
        <Entertainment />
      </PrivateRoute>
    ),
  },
]);

export default function Routes() {
  return (
    <Suspense fallback={<FullScreenLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
