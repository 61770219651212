import React, { Suspense } from "react";
import LayoutContainer from "../components/Layout/LayoutContainer";
import FullScreenLoading from "../components/Loader/FullScreenLoading";

export default function PrivateRoute({ children }) {
  return (
    <LayoutContainer>
      <Suspense fallback={<FullScreenLoading />}>{children}</Suspense>
    </LayoutContainer>
  );
}
