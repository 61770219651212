import React, { useState } from "react";
import classes from "./styles.module.scss";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";

export default function LayoutContainer({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <div className={classes.LayoutContainer}>
      <Navbar loggedIn={loggedIn} handleLogin={(val) => setLoggedIn(val)} />
      {children}
      <Footer />
    </div>
  );
}
