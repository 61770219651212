import React from "react";
import classes from "./styles.module.scss";

export default function Footer() {
  return (
    <div className={classes.Footer}>
      PetKahu &copy; {new Date().getFullYear()}
    </div>
  );
}
